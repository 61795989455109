import cn from 'classnames'
import { Barlow, Hanken_Grotesk } from 'next/font/google'
import localFont from 'next/font/local'
import '@/styles/index.scss'

const barlow = Barlow({
  subsets: ['latin'],
  display: 'swap',
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal', 'italic'],
  variable: '--barlow-font',
})

const hanken_grotesk = Hanken_Grotesk({
  subsets: ['latin'],
  display: 'swap',
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal', 'italic'],
  variable: '--grotesk-font',
})

const sang_bleu_empire = localFont({
  src: [
    {
      path: '../../public/fonts/SangBleuEmpire/SangBleuEmpire-RegularItalic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/SangBleuEmpire/SangBleuEmpire-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  variable: '--sangbleu-font',
})

function App({ Component, pageProps }) {
  return (
    <>
      <style
        jsx
        global
      >{`
        :root {
          --barlow-font: ${barlow.style.fontFamily};
          --grotesk-font: ${hanken_grotesk.style.fontFamily};
          --sangbleu-font: ${sang_bleu_empire.style.fontFamily};
        }
      `}</style>

      <Component {...pageProps} />
    </>
  )
}

export default App
